/* global Sentry */
import React from "react"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import { ErrorBoundary } from "react-error-boundary"
import FsLightbox from "fslightbox-react"
import Img from "gatsby-image"

import Carousel from "../Carousel"

const YouTubePlayer = React.forwardRef(({ src, ...props }, ref) => (
  <iframe
    ref={ref}
    width="100%"
    height="100%"
    src={src}
    frameBorder="0"
    controls="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    {...props}
  />
))

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const CoverCarousel = styled(Carousel)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease-out;
  background-color: black;

  &:hover {
    opacity: 0;
  }
`

const ProjectBox = styled(({ children, ...props }) => (
  <div {...props}>
    <div className="content">{children}</div>
  </div>
))`
  position: relative;
  width: 100%;
  padding-bottom: ${(9 / 16) * 100}%;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(1 / 3) * 100}%;
    padding-bottom: ${((9 / 16) * 100) / 3}%;
  }

  & .content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const Cover = styled(Img)`
  width: 100%;
  height: 100%;
`

const ProjectPreview = React.forwardRef(({ element, ...props }, ref) => {
  switch (element.__typename) {
    case "ElementsJson":
      return <YouTubePlayer ref={ref} src={element.src} {...props} />
    case "ImageSharp":
      return <Cover ref={ref} fluid={element.fluid} {...props} />
    default:
      return null
  }
})

const ProjectsGallery = ({ projects, ...props }) => {
  const [visible, setVisible] = React.useState(false)
  const slidersRef = React.useRef(null)

  if (slidersRef.current === null) {
    slidersRef.current = [...Array(projects.length)].map(() =>
      React.createRef()
    )
  }

  React.useEffect(() => {
    if (!visible) {
      return
    }
    const intervalID = setInterval(() => {
      slidersRef.current.forEach((sliderRef) => {
        sliderRef.current.slickNext()
      })
    }, 1250)

    return () => {
      clearInterval(intervalID)
    }
  }, [visible])

  const onChangeVisibility = (value: boolean) => {
    setVisible(value)
  }

  // FsLightbox is just badly designed library... especially the react version
  // Can not change simuntaniously projectID and toggler because it will not open
  // that is why I open it in effect
  const [galleryState, setGalleryState] = React.useState((): {
    projectID: string | null
    toggler: boolean
  } => ({
    projectID: null,
    toggler: false,
  }))
  const { toggler, projectID } = galleryState

  const getOnClickProject = (id: string | null) => () => {
    setGalleryState(() => ({
      projectID: id,
      toggler: false,
    }))
  }

  React.useEffect(() => {
    if (!projectID) return
    // open gallery on projectID change
    setGalleryState((prev) => ({
      ...prev,
      toggler: !prev.toggler,
    }))
  }, [projectID])

  return (
    <VisibilitySensor
      resizeCheck
      partialVisibility
      onChange={onChangeVisibility}
    >
      <>
        <Root {...props}>
          {projects.map(({ id, covers, elements }, index) => (
            <ProjectBox key={id} onClick={getOnClickProject(id)}>
              {visible && <ProjectPreview element={elements[0]} />}
              <CoverCarousel
                ref={slidersRef.current[index]}
                accessibility={false}
                infinite
                lazyLoad
                arrows={false}
                dots={false}
                appendDots={false}
                fade
                swipe={false}
              >
                {covers.map((cover, index) => (
                  <Cover key={index} fluid={cover.childImageSharp.fluid} />
                ))}
              </CoverCarousel>
            </ProjectBox>
          ))}
        </Root>
        <ErrorBoundary
          FallbackComponent={() => null}
          onError={(error, componentStack) => {
            Sentry.withScope((scope) => {
              scope.setTag("id", "FsLightbox")
              scope.setExtras(componentStack)
              Sentry.captureException(error)
            })
          }}
        >
          {projectID && (
            <>
              <FsLightbox
                key={projectID}
                toggler={toggler}
                sources={projects
                  .find((project) => project.id === projectID)
                  .elements.map((element) => {
                    switch (element.__typename) {
                      case "ImageSharp":
                        return element.fluid.src
                      default:
                        return element.src
                    }
                  })}
                onClose={getOnClickProject(null)}
                onOpen={() => {
                  // The gallery is adding margin to recomponse scroll bar - there is no scroll bar on our page
                  document.body.style.removeProperty("margin-right")
                }}
              />
            </>
          )}
        </ErrorBoundary>
      </>
    </VisibilitySensor>
  )
}

export default ProjectsGallery
