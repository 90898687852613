import React from "react"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import { useDebouncedCallback } from "use-debounce"
import useEventListener from "@use-it/event-listener"

import Video from "components/Video"

const VideoStyled = styled(Video)`
  width: 100%;
  height: auto;
`

const Root = styled.div`
  position: relative;
`

const VideoOnScroll = (props) => {
  const { iddleVideo, inVideo, mainVideo, outVideo, ...other } = props

  const videoStateMap = {
    0: iddleVideo,
    1: inVideo,
    2: mainVideo,
    3: outVideo,
  }

  const [rootHeight, setRootHeight] = React.useState(100)
  const rootRef = React.useRef()
  /**
   * 0 - iddle
   * 1 - in
   * 2 - main
   * 3 - out
   */
  const [state, setState] = React.useState({
    current: 0,
    finished: false,
    canNextPlay: false,
    next: null,
  })
  const [visible, setVisible] = React.useState(false)
  const video1Ref = React.useRef()
  const video2Ref = React.useRef()
  const currentVideoRef = React.useRef(video1Ref)
  const nextVideoRef = React.useRef(null)

  const setNextState = (next) => {
    setState((value) => ({
      ...value,
      canNextPlay: false,
      next,
    }))
  }

  const onChangeVisibility = (value) => {
    setVisible(value)
  }

  const getSetRootHeight = () => {
    setRootHeight(rootRef.current.getBoundingClientRect().height)
  }

  // Get video height and store it
  const {
    callback: debouncedSetVideoHeight
  } = useDebouncedCallback(
    () => {
      if (currentVideoRef.current.current.readyState > 0) {
        getSetRootHeight()
      } else {
        currentVideoRef.current.current.removeEventListener(
          "loadedmetadata",
          getSetRootHeight
        )
        currentVideoRef.current.current.addEventListener(
          "loadedmetadata",
          getSetRootHeight,
          { once: true }
        )
      }
    },
    // delay in ms
    16
  )

  useEventListener("resize", debouncedSetVideoHeight)

  React.useLayoutEffect(debouncedSetVideoHeight, [])

  React.useEffect(() => {}, [visible])

  React.useEffect(() => {
    if (
      (!visible && state.current === 0) || // not visible and iddle
      (visible && state.current === 2) // visible and main
    ) {
      nextVideoRef.current = null
      setState((prev) => ({
        ...prev,
        next: null,
        finished: false,
        canNextPlay: false,
      }))
      // nothing to do here
      return
    }
    const videoState = state.current
    if (!state.next) {
      if (currentVideoRef.current === video1Ref) {
        nextVideoRef.current = video2Ref
      } else {
        nextVideoRef.current = video1Ref
      }
    }
    if (visible) {
      switch (videoState) {
        case 0:
          setNextState(1)
          break
        case 1:
          setNextState(2)
          break
        case 3:
          setNextState(1)
          break
      }
    } else {
      switch (videoState) {
        case 1:
          setNextState(3)
          break
        case 2:
          setNextState(3)
          break
        case 3:
          setNextState(0)
          break
      }
    }
    const onEnded = () => {
      setState((prev) => ({
        ...prev,
        finished: true,
      }))
    }

    if (videoState === 2) {
      // immediatelly stop the video if the main is active
      onEnded()
    } else {
      currentVideoRef.current.current.addEventListener("ended", onEnded, {
        once: true,
      })
    }

    return () => {
      if (currentVideoRef.current) {
        currentVideoRef.current.current.removeEventListener("ended", onEnded)
      }
    }
  }, [state.current, visible])

  const prevNext = React.useRef(null)
  const nextNext = React.useRef(null)

  React.useEffect(() => {
    // Make sure next video is unmonted before changing to another next
    // Browsers does not support video src changing correctly. Video element has to be unmounted and mounted again when changing source
    if (
      state.next !== null &&
      prevNext.current !== null &&
      prevNext.current !== state.next
    ) {
      nextNext.current = state.next
      prevNext.current = null
      nextVideoRef.current = null
      setState((value) => ({
        ...value,
        canNextPlay: false,
        next: null,
      }))
      return
    } else if (nextNext.current) {
      nextVideoRef.current =
        currentVideoRef.current === video1Ref ? video2Ref : video1Ref
      const next = nextNext.current
      setState((value) => ({
        ...value,
        canNextPlay: false,
        next,
      }))
      nextNext.current = null
      return
    } else {
      prevNext.current = state.next
    }

    if (state.next === null) return

    const nextVideo = nextVideoRef.current
    const onCanPlay = () => {
      // switch next and current!
      setState((value) => ({
        ...value,
        canNextPlay: !!nextVideo.current,
      }))
    }
    if (nextVideo.current.readyState > 0) {
      onCanPlay()
    } else {
      nextVideo.current.addEventListener("canplay", onCanPlay, { once: true })
    }
    return () => {
      if (nextVideoRef.current) {
        nextVideo.current.removeEventListener("canplay", onCanPlay)
      }
    }
  }, [state.next])

  React.useEffect(() => {
    if (state.finished && state.canNextPlay) {
      currentVideoRef.current = nextVideoRef.current
      nextVideoRef.current = null
      currentVideoRef.current.current.play()
      // lets update the height too. Make sure it is correct
      getSetRootHeight()
      setState((prev) => ({
        next: null,
        current: prev.next,
        finished: false,
        canNextPlay: false,
      }))
    }
  }, [state.finished, state.canNextPlay])

  const hasNextVideo = !!nextVideoRef.current
  const isVideo1Next = hasNextVideo && nextVideoRef.current === video1Ref
  const isVideo2Next = hasNextVideo && nextVideoRef.current === video2Ref
  const currentVideoProps = {
    ...videoStateMap[state.current],
    loop: !hasNextVideo,
    autoPlay: true,
  }
  const nextVideoProps = {
    ...(Number.isFinite(state.next) ? videoStateMap[state.next] : {}),
    style: {
      position: "absolute",
      zIndex: -1,
      left: 0,
    },
  }

  return (
    <VisibilitySensor
      resizeCheck
      partialVisibility
      offset={{
        top: rootHeight,
        bottom: 0.8 * rootHeight,
      }}
      onChange={onChangeVisibility}
    >
      <Root ref={rootRef} {...other}>
        {(currentVideoRef.current === video1Ref || isVideo1Next) && (
          <VideoStyled
            ref={video1Ref}
            muted
            {...(isVideo1Next ? nextVideoProps : currentVideoProps)}
          />
        )}
        {(currentVideoRef.current === video2Ref || isVideo2Next) && (
          <VideoStyled
            ref={video2Ref}
            muted
            {...(isVideo2Next ? nextVideoProps : currentVideoProps)}
          />
        )}
      </Root>
    </VisibilitySensor>
  )
}

export default VideoOnScroll
