import styled from "styled-components"

import Video from "components/Video"

import scrollGif from "assets/scroll.gif"

export const VideoStyled = styled(Video)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }

  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }
`

export const ScrollGif = styled.div`
  width: 2px;
  height: 30px;
  background-image: url(${scrollGif});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

export const BackgroundDiv = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
`

export const FullWidthImg = styled.img`
  width: 100%;
`
