import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Parallax } from "react-scroll-parallax"
import VisibilitySensor from "react-visibility-sensor"

import Layout from "components/layout"
import Logo from "components/logo"
import SEO from "components/seo"
import SocialMedia from "components/SocialMedia"
import VideoOnScroll from "components/VideoOnScroll"
import ProjectsGallery from "components/ProjectsGallery"
import { useLightVersion } from "utils"
import SoundON from "assets/svg/volume-up-solid.inline.svg"
import SoundOFF from "assets/svg/volume-mute-solid.inline.svg"

import homeBackgroundVideoMp4 from "../../content/home/background/home-background.mp4"
import homeBackgroundVideoWebm from "../../content/home/background/home-background.webm"
import homeBackgroundImage from "../../content/home/background/light-background.jpg"
import facesIddle from "../../content/home/faces/01_faces_iddle.mp4"
import facesIddleWebm from "../../content/home/faces/01_faces_iddle.webm"
import facesUp from "../../content/home/faces/02_faces_up.mp4"
import facesUpWebm from "../../content/home/faces/02_faces_up.webm"
import facesMain from "../../content/home/faces/03_faces_main.mp4"
import facesMainWebm from "../../content/home/faces/03_faces_main.webm"
import facesDown from "../../content/home/faces/04_faces_down.mp4"
import facesDownWebm from "../../content/home/faces/04_faces_down.webm"
import lightFacesImg from "../../content/home/faces/light-faces.jpg"
import clientsBanner from "../../content/home/clients_banner.jpg"

import { VideoStyled, ScrollGif, BackgroundDiv, FullWidthImg } from "./_styles"

const LayoutStyled = styled(Layout)`
  font-family: "VCR OSD Mono", Arial, sans-serif;
  text-align: center;
  color: white;
`

const LogoStyled = styled(Logo)`
  width: 100%;
  max-width: 350px;
  margin: 1rem;
  height: auto;

  ${(props) => props.theme.breakpoints.up("sm")} {
    max-width: 60%;
  }
`

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateY(-100%);
  pointer-events: none;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const SocialMediaStyled = styled(SocialMedia)`
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const ContentContainer = styled.main`
  position: relative;
  background-color: black;
`

const Content = styled.p`
  font-size: ${15 / 16}rem;
  padding: 1.1rem;
  max-width: 834px;
  margin: auto;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: ${24 / 16}rem;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: ${30 / 16}rem;
  }
`

const MainVideoWrapper = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
`

const PrallaxVideoWrapper = styled(Parallax)`
  width: 100%;
  height: 100%;
  z-index: -1;

  & .parallax-inner {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  & .parallax-inner:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const ClientsImageContainer = styled(Content)`
  max-width: 1130px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 150px;
  }
`

const ClientsImage = styled.img.attrs(() => ({
  src: clientsBanner,
  alt: "Our clients",
}))`
  width: 100%;
  height: auto;
`

const IntroductionSectionWrapper = styled.div`
  position: relative;
  background-color: black;

  ${(props) => props.theme.breakpoints.up("md")} {
    background-color: initial;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    display: flex;
    align-items: center;
  }
`

const SoundSwitchWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;

  ${(props) => props.theme.breakpoints.up("md")} {
    right: 73px;
  }
`

const SoundSwitchIcon = styled(SoundOFF)`
  color: rgba(255, 255, 255, 0.85);
  width: 30px;
  height: 30px;
`

const IndexPage = () => {
  const wasMutedWhenVisible = React.useRef(true)
  const [muted, setMuted] = React.useState(true)
  const lightVersion = useLightVersion()

  const data = useStaticQuery(graphql`
    query {
      projects: allProjectsJson(sort: { fields: order }) {
        edges {
          node {
            id
            covers {
              childImageSharp {
                fluid(maxWidth: 640) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            parent {
              ... on File {
                name
                relativeDirectory
              }
            }
          }
        }
      }
      elements: allFile(
        filter: { absolutePath: { regex: "//home/projects/.*/elements/" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            children {
              ... on ImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
              ... on ElementsJson {
                src
              }
            }
            relativeDirectory
          }
        }
      }
    }
  `)

  // TODO: make it in graphql -> gatsby-node
  const projects = React.useMemo(() => {
    return data.projects.edges
      .map((edge) => ({
        ...edge.node,
      }))
      .map((project) => {
        const elements = data.elements.edges
          .map((edge) => edge.node)
          .filter((element) =>
            element.relativeDirectory.startsWith(
              `${project.parent.relativeDirectory}/${project.parent.name}`
            )
          )
          .map((elements) => elements.children[0])
        return {
          ...project,
          elements,
        }
      })
  }, [])

  return (
    <LayoutStyled>
      <SEO title="Home" />
      <VisibilitySensor
        partialVisibility
        onChange={(visibility) => {
          if (!visibility) {
            wasMutedWhenVisible.current = muted
            setMuted(true)
          } else if (!wasMutedWhenVisible.current) {
            setMuted(false)
          }
        }}
      >
        <MainVideoWrapper>
          <PrallaxVideoWrapper y={[-25, 25]}>
            {lightVersion ? (
              <BackgroundDiv src={homeBackgroundImage} />
            ) : (
              <VideoStyled
                webm={homeBackgroundVideoWebm}
                mp4={homeBackgroundVideoMp4}
                muted={muted}
                loop
                autoPlay
                preload="auto"
              />
            )}
          </PrallaxVideoWrapper>
          <ScrollGif />
          {!lightVersion && (
            <SoundSwitchWrapper>
              <SoundSwitchIcon
                onClick={() => {
                  setMuted((prev) => !prev)
                }}
                as={muted ? SoundOFF : SoundON}
              />
            </SoundSwitchWrapper>
          )}
        </MainVideoWrapper>
      </VisibilitySensor>
      <LogoContainer>
        <LogoStyled />
      </LogoContainer>
      <IntroductionSectionWrapper>
        <Content>
          {/* TODO: move the text to markdown content source */}
          *** DYSLECTIC GAMES STUDIO ***
          <br />
          DIGITAL AND TRADITIONAL MEDIA ARTISTS LIVING IN CRACOW, POLAND.
          <br />
          <br />
          5 DYSLECTICS, DEVOTED TO VIDEO GAMES, ART, SCIENCE AND TECHNOLOGY.
          <br />
          *** CREATING FOR YOU ***
        </Content>
      </IntroductionSectionWrapper>
      <ContentContainer>
        <SocialMediaStyled />
        <Content as="div" id="about-us" css="margin-bottom: -30px;">
          {lightVersion ? (
            <FullWidthImg src={lightFacesImg} alt="The team" />
          ) : (
            <VideoOnScroll
              iddleVideo={{
                mp4: facesIddle,
                webm: facesIddleWebm,
              }}
              inVideo={{
                mp4: facesUp,
                webm: facesUpWebm,
              }}
              mainVideo={{
                mp4: facesMain,
                webm: facesMainWebm,
              }}
              outVideo={{
                mp4: facesDown,
                webm: facesDownWebm,
              }}
            />
          )}
        </Content>
        <Content>
          *** DYSLECTIC GAMES TEAM ***
          <br />
          WHAT WILL HAPPEN WHEN 5 INDIVIDUALS FOCUS THEIR TALENTS ON THE SAME
          PROJECT?
        </Content>
        <Content>WE STARTED WORKING TOHETHER IN 2019.</Content>
        <Content>
          <br />
          OUR MOTTO? <br />
          *** IT HAS TO FEEL GOOD! ***
        </Content>
        <Content id="our-projects" css="margin-bottom: 1rem; margin-top: 2rem">
          THINGS WE DID:
        </Content>
        <ProjectsGallery projects={projects} />
        <Content css="margin-top: 2rem">WE WERE DEALING WITH:</Content>
        <ClientsImageContainer>
          <ClientsImage />
        </ClientsImageContainer>
      </ContentContainer>
    </LayoutStyled>
  )
}

export default IndexPage
